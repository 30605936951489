import { Outlet, useNavigate } from "react-router-dom";
import ActivePageStore from "../../../stores/activePageStore";
import Stores from "../../../stores";
import { inject, observer } from "mobx-react";
import { MainMenu } from "./components/MainMenu";
import { makeStyles } from "@fluentui/react-components";
import Logo from "./components/Logo";

interface ILayoutProps {
  activePageStore?: ActivePageStore;
}

const useContentWrapper = makeStyles({
  root: {
    width: "900px",
    margin: "0 auto",
  },
});

const useHeaderWrapper = makeStyles({
  root: {
    // backgroundImage: `url(${process.env.PUBLIC_URL + "/img/logo-bg.png"})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "top",
  },
});

const useLogoWrapper = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: "100px",
    paddingBottom: "25px",
    borderBottomWidth: "1px",
    borderBottomColor: "var(--colorCompoundBrandStroke)",
    borderBottomStyle: "solid",

    // backgroundImage: `url(${process.env.PUBLIC_URL + "/img/logo-bg.png"})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "top",
  },
});

const useTitleWrapper = makeStyles({
  root: {
    paddingLeft: "20px",
  },
});

const AppLayout = inject(Stores.ActivePageStore)(
  observer((props: ILayoutProps) => {
    const navigate = useNavigate();
    const contentWrapperStyle = useContentWrapper();
    const headerWrapperStyle = useHeaderWrapper();
    const logoWrapperStyle = useLogoWrapper();
    const titleWrapperStyle = useTitleWrapper();
    return (
      <>
        {/* header content */}
        <div className={headerWrapperStyle.root}>
          <div className={contentWrapperStyle.root}>
            <header>
              <div className={logoWrapperStyle.root}>
                <div>
                  <Logo />
                </div>
                <div className={titleWrapperStyle.root}>
                  <div>
                    <h1>Sud Ile de France Secourisme</h1>
                  </div>
                  <div>
                    <span>Les centres de formations en Ile-de-France</span>
                  </div>
                </div>
              </div>
              <div>
                <MainMenu
                  activePageId={props.activePageStore?.currentPage.id!}
                  onMenuChanged={(newId: any) => {
                    props.activePageStore?.setActivePage(newId);
                    navigate(props.activePageStore?.currentPage.route!);
                  }}
                />
              </div>
            </header>
          </div>
        </div>
        {/* main content */}
        <div className={contentWrapperStyle.root}>
          <div style={{ padding: "15px" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div>
                <div style={{ minWidth: "300px" }}>
                  <h2>Information</h2>
                  <div>
                    <div style={{ display: "flex", paddingBottom: "10px" }}>
                      <div style={{ width: "60px" }}>Address:</div>
                      <div>94290 Villeneuve-le-Roi, France</div>
                    </div>
                    <div style={{ display: "flex", paddingBottom: "10px" }}>
                      <div style={{ width: "60px" }}>Phone:</div>
                      <div>07 71 05 81 68</div>
                    </div>
                    <div style={{ display: "flex", paddingBottom: "10px" }}>
                      <div style={{ width: "60px" }}>Email:</div>
                      <div>sud-idf-secourisme@orange.fr</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {!props.activePageStore?.currentPage.hideTitle && (
                  <h2>{props.activePageStore?.currentPage.caption}</h2>
                )}

                <div>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer content */}
        <div></div>
      </>
    );
  })
);

export default AppLayout;
