import { usePageState } from "hooks";
import { inject, observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import Stores from "stores";
import PostsStore from "stores/postsStore";

//import { toast } from "react-toastify";

import { IPost } from "types";
import { parsErrors } from "utils";

interface IHomeProps {
  postsStore?: PostsStore;
}

const Home = inject(Stores.PostsStore)(
  observer((props: IHomeProps) => {
    const pageState = usePageState<IPost>();

    const initAction = useCallback(async () => {
      pageState.startLoading();
      try {
        await props.postsStore?.getPosts();
      } catch (error: any) {
        toast.error(parsErrors(error));
      } finally {
        pageState.stopLoading();
      }
    }, []);

    useEffect(() => {
      initAction();
    }, []);

    return (
      <div>
        <div>
          {props.postsStore?.posts.map((post) => {
            return (
              <div style={{ padding: "5px" }}>
                <h3>{post.title}</h3>
                <div>{post.modified}</div>
                <div dangerouslySetInnerHTML={{ __html: post.body }}></div>
              </div>
            );
          })}
        </div>
      </div>
    );
  })
);

export default Home;
