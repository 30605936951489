import "./index.css";

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "mobx-react";
import App from "./App";
import { initializeStores } from "./stores";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";

const stores = initializeStores();
const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

const AppProvider = () => {
  return (
    <React.StrictMode>
      <Suspense fallback={<>Suspense Loading Page</>}>
        <Provider {...stores}>
          <FluentProvider theme={webLightTheme}>
            <App />
          </FluentProvider>
        </Provider>
      </Suspense>
    </React.StrictMode>
  );
};

root.render(<AppProvider />);
