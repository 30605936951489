import { action, observable } from "mobx";
import type { IPost } from "../types";
import posts from "./data/post";

class PostsStore {
  @observable currentPost?: IPost;
  @observable posts: Array<IPost> = [];

  @action
  async getPosts() {
    this.posts = posts;
  }
}

export default PostsStore;
