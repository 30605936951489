import ActivePageStore from "./activePageStore";
import PostsStore from "./postsStore";

export function initializeStores() {
  return {
    activePageStore: new ActivePageStore(),
    postsStore: new PostsStore(),
  };
}

export default class Stores {
  static ActivePageStore: string = "activePageStore";
  static PostsStore: string = "postsStore";
}
