import {
  makeStyles,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabListProps,
} from "@fluentui/react-components";
import pageSettings from "config/pages";

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    rowGap: "20px",
  },
});

interface IMainMenuProps extends Partial<TabListProps> {
  activePageId: string;
  onMenuChanged: (newId: string) => void;
}

export const MainMenu = (props: IMainMenuProps) => {
  const styles = useStyles();

  const handleTabChange = (event: SelectTabEvent, data: SelectTabData) => {
    props.onMenuChanged(data.value as string);
  };

  return (
    <div className={styles.root}>
      <TabList
        {...props}
        defaultSelectedValue={props.activePageId}
        onTabSelect={handleTabChange}
      >
        {pageSettings.map((page) => {
          return (
            <Tab key={page.id} id={page.id} value={page.id}>
              {page.title}
            </Tab>
          );
        })}
      </TabList>
    </div>
  );
};
