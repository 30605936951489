const baseRouteUrls = {
  baseUrl: "/",
  calendrier: "/calendrier",
  contact: "/contact",

  exception: "/exception/:errorCode",
  error401Url: "/exception/401",
  error404Url: "/exception/404",
  error500Url: "/exception/500",
};

export default baseRouteUrls;
