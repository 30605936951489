import AppRouteBuilder from "./components/systems/AppRouteBuilder";
import { ToastContainer } from "react-toastify";

import "./App.css";
import "styles/reactToastify/toastify.customStyles.css";

function App() {
  return (
    <div className="app">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        draggable={false}
        enableMultiContainer={true}
        style={{ zIndex: "100000000" }}
      />
      <AppRouteBuilder />;
    </div>
  );
}

export default App;
