import { IPage } from "../../types";
import { pageIds, urls } from "../../utils/constants";

export const accueilPage: IPage = {
  id: pageIds.accueil,
  title: "Accueil",
  caption: "News",
  route: urls.baseUrl,
};

export const calendrierPage: IPage = {
  id: pageIds.calendrier,
  title: "Calendrier",
  caption: "Calendrier Caption",
  route: urls.calendrier,
};

export const contactPage: IPage = {
  id: pageIds.contact,
  title: "Contact",
  caption: "Contact Caption",
  route: urls.contact,
};

const pageSettings: IPage[] = [accueilPage, calendrierPage, contactPage];

export default pageSettings;
