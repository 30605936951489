import { Image, ImageProps } from "@fluentui/react-components";

const Logo = (props: ImageProps) => {
  return (
    <Image
      alt="Erik's avatar"
      //shape="circular"
      src={process.env.PUBLIC_URL + "/img/logo.png"}
      height={100}
      width={80}
    />
  );
};

export default Logo;
