const baseServerUrls = {
  accuracy: "/projects/accuracy",
  accuracyVector: "/projects/accuracy-vector",
  algorithm: "/projects/algorithm",
  algorithmPoint: "/projects/algorithm-point",
  algorithmPointType: "/projects/algorithm-point-type",
  algorithmType: "/projects/algorithm-type",
  area: "/projects/area",
  areaDesign: "/projects/area-design",
  attributeType: "/projects/attribute-type",
  building: "/projects/building",
  buildingDesign: "/projects/building-design",
  cable: "/projects/cable",
  cableConnector: "/projects/cable-connector",
  cableConnectorContact: "/projects/cable-connector-contact",
  cableType: "/projects/cable-type",
  cableWireEdge: "/projects/cable-wire-edge",
  circuitSwAssociation: "/projects/circuit-sw-association",
  circuitSwAssociationType: "/projects/circuit-sw-association-type",
  cisSystem: "/projects/cis-system",
  component: "/projects/component",
  configuration: "/projects/configuration",
  connectorType: "/projects/connector-type",
  drop: "/projects/drop",
  entityAdditionalAttribute: "/projects/entity-additional-attribute",
  equipment: "/projects/equipment",
  equipmentInstallationPlace: "/projects/equipment-installation-place",
  equipmentType: "/projects/equipment-type",
  level: "/projects/level",
  levelDesign: "/projects/level-design",
  manufacturer: "/projects/manufacturer",
  partNumber: "/projects/part-number",
  physicalValue: "/projects/physical-value",
  physicalValueUnit: "/projects/physical-value-unit",
  physicalValueUnitSystem: "/projects/physical-value-unit-system",
  point: "/projects/point",
  pointType: "/projects/point-type",
  project: "/projects/project",
  room: "/projects/room",
  roomDesign: "/projects/room-design",
  sensor: "/projects/sensor",
  signal: "/projects/signal",
  signalCircuit: "/projects/signal-circuit",
  signalType: "/projects/signal-type",
  signalTypeCircuit: "/projects/signal-type-circuit",
  signalTypeStructure: "/projects/signal-type-structure",
  site: "/projects/site",
  supply: "/projects/supply",
  terminal: "/projects/terminal",
  terminalClampType: "/projects/terminal-clamp-type",
  terminalDevice: "/projects/terminal-device",
  terminalDeviceType: "/projects/terminal-device-type",
  terminalOfTypicalTerminalDevice:
    "/projects/terminal-of-typical-terminal-device",
  terminalType: "/projects/terminal-type",
  transformationFunction: "/projects/transformation-function",
  transformationState: "/projects/transformation-state",
  transformationVector: "/projects/transformation-vector",
  typicalAlgorithmPoint: "/projects/typical-algorithm-point",
  typicalEquipmentCircuitSwAssociation:
    "/projects/typical-equipment-circuit-sw-association",
  typicalEquipmentInstallationPlace:
    "/projects/typical-equipment-installation-place",
  typicalEquipmentTerminalDevice: "/projects/typical-equipment-terminal-device",
  value2StateTransformation: "/projects/value2-state-transformation",
  value2ValueTransformation: "/projects/value2-value-transformation",
  wire: "/projects/wire",
  wireContact: "/projects/wire-contact",
  wireEndConstruction: "/projects/wire-end-construction",
  wireType: "/projects/wire-type",
};

export default baseServerUrls;
