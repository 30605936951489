import { Navigate, RouteObject } from "react-router-dom";
import { urls } from "../../utils/constants";
import AppLayout from "components/ui/AppLayout";
import Home from "pages/Home";
import Calendrier from "pages/Calendrier";
import Contact from "pages/Contact";

export const applicationRoutes: RouteObject[] = [
  {
    path: urls.exception,
    element: <>error</>,
    //element: <ExceptionPage />,
  },

  {
    path: urls.baseUrl,
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: urls.calendrier,
        element: <Calendrier />,
      },
      {
        path: urls.contact,
        element: <Contact />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate replace to={urls.error404Url} />,
  },
];
