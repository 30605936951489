import { useBoolean } from "hooks";
import { useState } from "react";

export interface IPageStateOptions {
  loading: boolean;
}
export interface IPageState<T = {}> {
  selectedId?: string;
  model?: T;
  isLoading: boolean;
  // isSetupPanelOpend: boolean;
  // isUpdatePanelOpend: boolean;
  // isViewPanelOpend: boolean;

  setSelectedId: (id: string) => void;
  setModel: (model?: T) => void;

  startLoading: () => void;
  stopLoading: () => void;

  // openSetupPanel: () => void;
  // closeSetupPanel: () => void;

  // openUpdatePanel: () => void;
  // closeUpdatePanel: () => void;

  // openViewPanel: () => void;
  // closeViewPanel: () => void;
}

export const usePageState = <T = {}>(
  options?: IPageStateOptions
): IPageState<T> => {
  const [selectedId, setSelectedId] = useState<string>();
  const [model, setModel] = useState<T>();
  const [isLoading, { setTrue: startLoading, setFalse: stopLoading }] =
    useBoolean(options?.loading ?? false);
  // const [
  //   isSetupPanelOpend,
  //   { setTrue: openSetupPanel, setFalse: closeSetupPanel },
  // ] = useBoolean(false);
  // const [
  //   isUpdatePanelOpend,
  //   { setTrue: openUpdatePanel, setFalse: closeUpdatePanel },
  // ] = useBoolean(false);
  // const [
  //   isViewPanelOpend,
  //   { setTrue: openViewPanel, setFalse: closeViewPanel },
  // ] = useBoolean(false);

  return {
    selectedId,
    model,
    isLoading,
    // isSetupPanelOpend,
    // isUpdatePanelOpend,
    // isViewPanelOpend,

    setSelectedId,
    setModel,

    startLoading,
    stopLoading,

    // openSetupPanel,
    // closeSetupPanel,

    // openUpdatePanel,
    // closeUpdatePanel,

    // openViewPanel,
    // closeViewPanel,
  };
};
