import { IPost } from "types/IPost";

const posts: IPost[] = [
  {
    id: "1",
    title: "Title",
    modified: "01.06.2024",
    body: "TS1208: 'post.ts' cannot be compiled under '--isolatedModules' because it is considered a global script file. Add an import, export, or an empty 'export {}' statement to make it a module.",
  },
  {
    id: "2",
    title: "Title 2",
    modified: "10.05.2024",
    body: "Il me reste quelques places pour la formation intensive BNSSA du 25 au 30 avril 2022 dans le VAL DE MARNE à LIMEÎL BREVANNES Le pré-requis est le PSE 1 N’hésitez pas à nous contactez sur sud-idf-secourisme@orange.fr ou au 0683064723 N’HÉSITEZ À PARTAGER !!",
  },

  {
    id: "3",
    title: "Title 2",
    modified: "10.05.2024",
    body: `<div class="dropdown">
  <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-expanded="true">
    Dropdown
    <span class="caret"></span>
  </button>
  <ul class="dropdown-menu" role="menu" aria-labelledby="dropdownMenu1">
    <li role="presentation"><a role="menuitem" tabindex="-1" href="#">Action</a></li>
    <li role="presentation"><a role="menuitem" tabindex="-1" href="#">Another action</a></li>
    <li role="presentation"><a role="menuitem" tabindex="-1" href="#">Something else here</a></li>
    <li role="presentation"><a role="menuitem" tabindex="-1" href="#">Separated link</a></li>
  </ul>
</div>`,
  },
];

export default posts;
