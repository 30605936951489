import { action, observable } from "mobx";
import type { IPage } from "../types";
import pageSettings, { accueilPage } from "../config/pages";

class ActivePageStore {
  @observable currentPage: IPage = accueilPage;

  @action
  async setActivePage(activePageId: string) {
    if (this.currentPage.id !== activePageId) {
      pageSettings.map((page) => {
        if (page.id === activePageId) {
          this.currentPage = page;
        }
      });
    }
  }
}

export default ActivePageStore;
