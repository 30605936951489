import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { applicationRoutes } from "../../../config/routing";

export interface IRouteBuilderProps {}

const AppRouteBuilder = ({}: IRouteBuilderProps) => {
  const router = createBrowserRouter(applicationRoutes);

  return (
    <RouterProvider router={router} fallbackElement={<>fallbackElement</>} />
  );
};

export default AppRouteBuilder;
